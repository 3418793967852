@font-face {
    font-family: 'Inter';
    src: url('src/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}

@font-face {
    font-family: 'Azeret Mono';
    src: url('src/fonts/Azeret_Mono/AzeretMono-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
}

@media (pointer: fine) {
    @supports (not selector(::-webkit-scrollbar)) {
        :root {
            scrollbar-width: auto;
            scrollbar-color: #787878 #ffffff;
        }
    }

    ::-webkit-scrollbar {
        width: 16px;
    }

    ::-webkit-scrollbar-track {
        background: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #bcc1cb;
        border-radius: 10px;
        border: 4px solid #ffffff;
    }
}

.noRoundedInput .MuiOutlinedInput-root {
    border-radius: 0;
}

@media print {
    .b24-widget-button-wrapper,
    .bx-livechat-wrapper {
        display: none !important;
    }
}
